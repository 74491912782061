










import { 
  defineComponent,
  useRoute,
  useRouter,
  useContext,
  computed,
  onMounted,
  ref,
} from "@nuxtjs/composition-api";
import { merge } from "lodash-es";
import { useApi } from '~/composables/useApi';
import PaymentLoader from '~/modules/fortytwo/payment/components/PaymentLoader.vue';
import { usePaymentStore } from '~/modules/fortytwo/payment/stores/payment';

import Vue from 'vue';

export default defineComponent({
  name: "Redirect",
  // using the empty layout.
  layout: 'empty',
  components: {
    PaymentLoader,
  },
  setup() {
    const { query } = useApi();
    const route = useRoute();
    const { localePath,localeRoute, app, app:{i18n} } = useContext();
    const formRef = ref(null);
    const paymentStore = usePaymentStore();
    const context = app.$vsf;

    const orderNumber = computed(() => route.value.query.order ?? '');
    const router = useRouter();
    const paymentLoadingMessage = i18n.t("Redirecting you to the payment gateway. Please wait...");

    const rUrl = ref();
    const err = ref();

    type Form = {
      access_key: String
      profile_id: String
      ignore_avs: String
      ignore_cvn: String
      transaction_uuid: String
      unsigned_field_names: String
      payment_method: String
      signed_date_time: String
      locale: String
      transaction_type: String
      reference_number: String
      amount: String
      currency: String
      tax_amount: String
      partner_solution_id: String
      auth_indicator: String
      merchant_secure_data1: String
      bill_to_forename: String
      bill_to_surname: String
      bill_to_email: String
      bill_to_company_name: String
      bill_to_phone: String
      bill_to_address_line1: String
      bill_to_address_line2: String
      bill_to_address_city: String
      bill_to_address_postal_code: String
      bill_to_address_country: String
      bill_address1: String
      bill_address2: String
      bill_city: String
      bill_country: String
      ship_to_forename: String
      ship_to_surname: String
      ship_to_email: String
      ship_to_company_name: String
      ship_to_address_line1: String
      ship_to_address_line2: String
      ship_to_phone: String
      ship_to_address_city: String
      ship_to_address_postal_code: String
      ship_to_address_country: String
      merchant_defined_data1: String
      merchant_defined_data2: String
      merchant_defined_data3: String
      merchant_defined_data4: String
      merchant_defined_data5: String
      merchant_defined_data6: String
      merchant_defined_data21: String
      merchant_defined_data23: String
      consumer_id: String
      customer_ip_address: String
      customer_email: String
      signed_field_names: String
      signature: String
    };
    const form = ref<Form>({
      access_key: "",
      profile_id: "",
      ignore_avs: "",
      ignore_cvn: "",
      transaction_uuid: "",
      unsigned_field_names: "",
      payment_method: "",
      signed_date_time: "",
      locale: "",
      transaction_type: "",
      reference_number: "",
      amount: "",
      currency: "",
      tax_amount: "",
      partner_solution_id: "",
      auth_indicator: "",
      merchant_secure_data1: "",
      bill_to_forename: "",
      bill_to_surname: "",
      bill_to_email: "",
      bill_to_company_name: "",
      bill_to_phone: "",
      bill_to_address_line1: "",
      bill_to_address_line2: "",
      bill_to_address_city: "",
      bill_to_address_postal_code: "",
      bill_to_address_country: "",
      bill_address1: "",
      bill_address2: "",
      bill_city: "",
      bill_country: "",
      ship_to_forename: "",
      ship_to_surname: "",
      ship_to_email: "",
      ship_to_company_name: "",
      ship_to_address_line1: "",
      ship_to_address_line2: "",
      ship_to_phone: "",
      ship_to_address_city: "",
      ship_to_address_postal_code: "",
      ship_to_address_country: "",
      merchant_defined_data1: "",
      merchant_defined_data2: "",
      merchant_defined_data3: "",
      merchant_defined_data4: "",
      merchant_defined_data5: "",
      merchant_defined_data6: "",
      merchant_defined_data21: "",
      merchant_defined_data23: "",
      consumer_id: "",
      customer_ip_address: "",
      customer_email: "",
      signed_field_names: "",
      signature: ""
    });

    const cybsQuery:any = ref({
      error: "",
      request_url: "",
      access_key: "",
      profile_id: "",
      ignore_avs: "",
      ignore_cvn: "",
      transaction_uuid: "",
      unsigned_field_names: "",
      payment_method: "",
      signed_date_time: "",
      locale: "",
      transaction_type: "",
      reference_number: "",
      amount: "",
      currency: "",
      tax_amount: "",
      partner_solution_id: "",
      auth_indicator: "",
      merchant_secure_data1: "",
      bill_to_forename: "",
      bill_to_surname: "",
      bill_to_email: "",
      bill_to_company_name: "",
      bill_to_phone: "",
      bill_to_address_line1: "",
      bill_to_address_line2: "",
      bill_to_address_city: "",
      bill_to_address_postal_code: "",
      bill_to_address_country: "",
      bill_address1: "",
      bill_address2: "",
      bill_city: "",
      bill_country: "",
      ship_to_forename: "",
      ship_to_surname: "",
      ship_to_email: "",
      ship_to_company_name: "",
      ship_to_address_line1: "",
      ship_to_address_line2: "",
      ship_to_phone: "",
      ship_to_address_city: "",
      ship_to_address_postal_code: "",
      ship_to_address_country: "",
      merchant_defined_data1: "",
      merchant_defined_data2: "",
      merchant_defined_data3: "",
      merchant_defined_data4: "",
      merchant_defined_data5: "",
      merchant_defined_data6: "",
      merchant_defined_data21: "",
      merchant_defined_data23: "",
      consumer_id: "",
      customer_ip_address: "",
      customer_email: "",
      signed_field_names: "",
      signature: ""
    });

    const myGraphqlQuery = `
    query {
      cybsGateway (order_id: "${orderNumber.value}") {
        error
        access_key
        profile_id
        ignore_avs
        ignore_cvn
        transaction_uuid
        payment_method
        signed_date_time
        locale
        transaction_type
        reference_number
        amount
        currency
        request_url
        tax_amount
        partner_solution_id
        auth_indicator
        merchant_secure_data1
        bill_to_forename
        bill_to_surname
        bill_to_email
        bill_to_company_name
        bill_to_phone
        bill_to_address_line1
        bill_to_address_line2
        bill_to_address_city
        bill_to_address_postal_code
        bill_to_address_country
        bill_address1
        bill_address2
        bill_city
        bill_country
        ship_to_forename
        ship_to_surname
        ship_to_email
        ship_to_company_name
        ship_to_address_line1
        ship_to_address_line2
        ship_to_phone
        ship_to_address_city
        ship_to_address_postal_code
        ship_to_address_country
        merchant_defined_data1
        merchant_defined_data2
        merchant_defined_data3
        merchant_defined_data4
        merchant_defined_data5
        merchant_defined_data6
        merchant_defined_data21
        merchant_defined_data23
        consumer_id
        customer_ip_address
        customer_email
        unsigned_field_names
        signed_field_names
        signature
      }
    }
    `;

    const fetchCyberSourceExtendedData = async () => {
      const { data : { cybsGateway } } = await query<{ cybsGateway }>(myGraphqlQuery);
      cybsQuery.value = merge(cybsQuery.value, cybsGateway);

      console.log('hehe');
      console.log(cybsQuery);

      err.value = cybsQuery?.value?.error;
      //assign post action url to rUrl
      rUrl.value = cybsQuery.value?.request_url;

      //remove error, request_url and __typename from cybsQuery object
      Vue.delete(cybsQuery.value, 'error');
      Vue.delete(cybsQuery.value, 'request_url');
      Vue.delete(cybsQuery.value, '__typename');
    };
    // console.log(cybsQuery);

    const onSubmit = () => {
      //any future modified code can be write over here.
    };

    const redirectToCart = async () => {
      await router.push(localeRoute({ name: 'home' }));
    };

    onMounted(async () => {
      //get form data with GraphQL
      await Promise.all([fetchCyberSourceExtendedData()]);
    
      //validation
      if (err.value) {
        console.log('kenot. redirect back to cart babeh');
        //error = redirect back to cart
        await paymentStore.setMessage("There was an error with the payment process.");
        const paymentRoute = app.localeRoute({
            name: "cart",
          });
          await router.push(paymentRoute);
      } else {
          //submit form
          await formRef.value.submit();
      }
    });

    return {
      cybsQuery,
      form,
      onSubmit,
      formRef,
      rUrl,
      paymentLoadingMessage,
    };
  },
});
