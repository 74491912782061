<template id="payemnt_loader">

<div class="payment-loading-container">
    <SfImage
        class="payment-loading"
        src="/custom/fortytwo-loading-icon.gif"
        :width="214"
        alt="room"
        placeholder="/custom/fortytwo-loading-icon.gif"
        image-tag="nuxt-img"
        :nuxt-img-config="{
        fit: 'contain',
        }"
    />
    <span class="payment-loading-label">
        {{ message }}
    </span>
</div>

</template>

<script>
import { SfImage } from "@storefront-ui/vue";
export default {
  name: "PaymentLoader",
  components: {
    SfImage,
  },
  props: {
    message: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-loading-container{
    margin: 0 auto;
    height: 100%;
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    align-items: center;

    @include for-mobile {
        width: 87%;
    }

    @include for-tablet {
        width: 91%;
    }
    
    @include for-desktop {
        width: 95%;
    }
}
.payment-loading-label{
    color: var(--Black, #333);
    font-family: var(--font-family--primary);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    @include for-mobile {
        font-size: 12px;
    }

    @include for-tablet {
        font-size: 14px;
    }
    
    @include for-desktop {
        font-size: 16px;
    }
}
</style>